<template>
  <div class="container dark:bg-gray-900 dark:text-gray-300">
    <StoreEmpty
      v-if="error || !data"
      class="my-24"
      title="Oops! There was an error."
      desc="Either you hit the invalid URL or the page is not available anymore."
      icon="IconsNotoBrokenHeart"
      action-label="Go To Home"
      :action-route="{ name: 'biz', params: { biz: subdomain } }"
    />

    <div v-else class="max-w-2xl mx-auto">
      <p
        class="page-title font-display font-bold text-xl leading-none lg:text-4xl p-4 sm:px-0 sm:pt-8 md:pt-12"
      >
        {{ data?.name }}
      </p>

      <div v-if="data?.cover?.url" class="aspect-w-16 aspect-h-9">
        <img :src="data?.cover?.url" :alt="data?.name" />
      </div>
      <div class="p-4 sm:px-0" v-if="data?.data">
        <MDCRenderer :body="data.data.body" :data="data.data.data" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useBizStore } from "@/store/index";
import page from "@/graph/page.gql";
import { parseMarkdown } from "@nuxtjs/mdc/runtime";

definePageMeta({
  layout: "store",
  scrollToTop: true,
});

const route = useRoute();

const bizStore = useBizStore();
const { subdomain } = storeToRefs(bizStore);

const { data, error } = await useAsyncData("markdown", () => {
  const { clients } = useApollo();
  return clients.default
    .query({
      query: page,
      variables: {
        slug: route.params.slug,
        subdomain: subdomain.value,
      },
    })
    .then(async (response) => {
      /**
       * Checking content here as draft page slug content doesn't exist
       * returning nothing show error page
       */
      if (response.data.pageBySlug?.content) {
        const markedDownData = await parseMarkdown(
          response.data.pageBySlug.content
        );
        return {
          data: markedDownData,
          cover: response.data.pageBySlug.cover,
          name: response.data.pageBySlug.name,
        };
      }
    })
    .catch((error) => {
      return error;
    });
});
</script>
